.highlighted {
  background-color: #d51515;
  border-radius: 5%;
  padding: 1px;
}

.highlighted:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: #181260;
  transform: translateX(-50%);
  white-space: nowrap;
}
